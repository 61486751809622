export const REQUEST_CATEGORIES = "API/REQUEST_CATEGORIES"
export const REQUEST_PRODUCTS = "API/REQUEST_PRODUCTS"
export const REQUEST_ALL_PRODUCTS_CATEGORIES = "API/REQUEST_ALL_PRODUCTS_CATEGORIES"
export const REQUEST_ONE_PRODUCT = "API/REQUEST_ONE_PRODUCT"
export const REQUEST_SUBCATEGORIES = "API/REQUEST_SUBCATEGORIES"
export const REQUEST_CREATE_ORDER = "API/POST/REQUEST_CREATE_ORDER"
export const REQUEST_CREATE_QUESTION = "API/POST/REQUEST_CREATE_QUESTION"
export const REQUEST_CREATE_SERVICE_ORDER = "API/POST/REQUEST_CREATE_SERVICE_ORDER"
export const FETCH_CATEGORIES = "API/FETCH_CATEGORIES"
export const FETCH_PRODUCTS = "API/FETCH_PRODUCTS"
export const FETCH_ONE_PRODUCT = "API/FETCH_ONE_PRODUCT"
export const SET_ONE_PRODUCT = "API/SET_ONE_PRODUCT"
export const FETCH_SUBCATEGORIES = "API/FETCH_SUBCATEGORIES"
export const FETCH_CREATE_ORDER = "API/POST/FETCH_CREATE_ORDER"
export const CREATE_ORDER_SUCCESS = "API/CREATE_ORDER_SUCCESS"
export const CREATE_ORDER_FAILED = "API/CREATE_ORDER_FAILED"
export const RESET_ORDER_INFORMATION = "API/RESET_ORDER_INFORMATION"
export const FETCH_CREATE_QUESTION = "API/POST/FETCH_CREATE_QUESTION"
export const CREATE_QUESTION_SUCCESS = "API/CREATE_QUESTION_SUCCESS"
export const CREATE_QUESTION_FAILED = "API/CREATE_QUESTION_FAILED"
export const FETCH_CREATE_SERVICE_ORDER = "API/POST/FETCH_CREATE_SERVICE_ORDER"
export const CREATE_SERVICE_ORDER_SUCCESS = "API/CREATE_SERVICE_ORDER_SUCCESS"
export const CREATE_SERVICE_ORDER_FAILED = "API/CREATE_SERVICE_ORDER_FAILED"
export const REQUEST_SERVICES = "API/REQUEST_SERVICES"
export const FETCH_SERVICES = "API/FETCH_SERVICES"
export const REQUEST_ONE_SERVICE = "API/REQUEST_ONE_SERVICE"
export const FETCH_ONE_SERVICE = "API/FETCH_ONE_SERVICE"
export const INIT_RESET_SUBCATEGORIES = "API/INIT_RESET_SUBCATEGORIES"
export const RESET_SUBCATEGORIES = "API/RESET_SUBCATEGORIES"


export const SAVE_FILTER = "FILTER/SAVE_FILTER"
export const SET_ACTIVE_FILTERS = "FILTER/SET_ACTIVE_FILTERS"

export const SAVE_SORT = "SORT/SAVE_SORT"

export const SAVE_SEARCHED = "SEARCH/SAVE_SEARCHED"


export const REQUEST_NEWS = "API/REQUEST_NEXT_NEWS"
export const FETCH_NEWS = "API/FETCH_POST_NEWS"
export const FETCH_POST_IMAGES = "API/FETCH_POST_IMAGES"