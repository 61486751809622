export const Footer = () => {
    return (
        <div className="footer grid">
            <div className="logo-footer grid">ARPK</div>
            <div className="info-footer grid">
                <div className="item">Адрес:</div>
                <div className="item">Осенняя ул., 23, Москва • эт. 9, оф. 35</div>
                <div className="item">Контакты:</div>
                <div className="item">+7 (495) 363-68-62</div>
                <div className="item">Время работы:</div>
                <div className="item">10-19 пн-пт; 11-19 сб-вс</div>
            </div>
        </div>
    )
}